<template>
    <el-card>
        <template #header>
            <div style="display: flex; justify-content: end; align-items: center;height: 40px;">
            </div>
        </template>

        <el-table :data="tableData" v-loading="isloading" stripe style="width: 100%" :row-style="rowStyle">
            <el-table-column label="结算时间">
                <template #default="scope">
                    <div v-for="(item, index) in scope.row.confirm_records" :key="index">
                        <div>{{ item.month }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="佣金项目">
                <template #default="scope">
                    <div v-for="(item, index) in scope.row.confirm_records" :key="index">
                        <div>{{ item.title }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="should_checkout_amount" label="应结佣金" />
            <el-table-column prop="agent_name" label="归属代理">
                <template #default="scope">
                    <div>{{ scope.row.confirm_records[0].agent_name }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="audit_status_text" label="审核状态">
                <template #default="scope">
                    <div :class="getAuditStatusClass(scope.row.audit_status)">
                        {{ scope.row.audit_status_text }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
                <template #default="scope">
                    <el-button type="warning" @click="auditCommission(scope.row)">
                        审核
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination" style="display: flex;justify-content: flex-end;">
            <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                :hide-on-single-page="false">
            </el-pagination>
        </div>
    </el-card>

    <el-dialog v-model="dialogFormVisible" title="审核佣金申请" width="560px" draggable>
        <el-form :model="form" class="custom-form" label-width="120px">
            <el-form-item label="归属代理：" prop="agent_name" :label-width="formLabelWidth">
                {{ form.agent_name }}
            </el-form-item>
            <el-form-item label="结算时间：" prop="months" :label-width="formLabelWidth">
                {{ form.months.join(', ') }}
            </el-form-item>
            <el-form-item label="佣金项目：" prop="titles" :label-width="formLabelWidth">
                {{ form.titles.join(', ') }}
            </el-form-item>
            <el-form-item label="应结佣金：" prop="should_checkout_amount" :label-width="formLabelWidth">
                {{ form.should_checkout_amount }}
            </el-form-item>
            <el-form-item label="发票图片：" :label-width="formLabelWidth">
                <el-image style="width: 100px; height: 100px" :src="form.imgUrl" :preview-src-list="previewSrcList"
                    fit="cover" />
            </el-form-item>
            <el-form-item label="审批结果：" :label-width="formLabelWidth">
                <el-radio-group v-model="form.audit_status">
                    <el-radio :label="2" size="large">不通过</el-radio>
                    <el-radio :label="1" size="large">通过</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：" :label-width="formLabelWidth">
                <el-input v-model="form.audit_remark" autocomplete="off" />
            </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center;">
            <el-button @click="dialogFormVisible = false" style="width: 80px;">取消</el-button>
            <el-button type="primary" @click="submitAudit" style="width: 80px;">保存</el-button>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';
const { proxy } = getCurrentInstance();
const tableData = ref([]);
const dialogFormVisible = ref(false);
const formLabelWidth = '120px';
let isloading = ref(false);
const form = ref({
    agent_name: '',
    months: [],
    titles: [],
    should_checkout_amount: '',
    detail_sheet: '',
    audit_status: null,
    audit_remark: ''
});
const previewSrcList = ref([]);
let pageinfo = ref({
    perPage: 0, // 每页多少条
    total: 0, // 共计多少
    totalPages: 0, // 共计多少页
    currentPage: 1, // 当前页数
});

const pageChange = (page) => {
    hasSearch(page);
};

const hasSearch = (page) => {
    isloading.value = true;
    let info = { page: page };
    commissionAuditList(info);
};


// 佣金列表
const commissionAuditList = async (info) => {
    try {
        isloading.value = true; // 开始加载
        const response = await proxy.$api.agents.commissionAuditList(info);
        tableData.value = response.data.data;
        pageinfo.value = {
            perPage: response.data.pagination.perPage,
            total: response.data.pagination.total,
            totalPages: response.data.pagination.totalPages,
            currentPage: response.data.pagination.currentPage
        };
    } catch (error) {
        proxy.$message.error('获取佣金列表失败'); // 请求失败处理
    } finally {
        isloading.value = false;
    }
};

// 打开审核
const auditCommission = (row) => {
    form.value = {
        id: row.id,
        agent_name: row.confirm_records[0].agent_name,
        months: row.confirm_records.map(record => record.month),
        titles: row.confirm_records.map(record => record.title),
        should_checkout_amount: row.should_checkout_amount,
        detail_sheet: row.detail_sheet,
        imgUrl: row.invoice_file_url
    };
    previewSrcList.value = [row.invoice_file_url];
    dialogFormVisible.value = true;
};

//提交审核
const submitAudit = async () => {
    try {
        const response = await proxy.$api.agents.commissionAudit({
            id: form.value.id,
            audit_remark: form.value.audit_remark,
            audit_status: form.value.audit_status
        });
        if (response.status === 200) {
            proxy.$message.success('审核成功');
            dialogFormVisible.value = false;
            commissionAuditList(); // 刷新列表
        }
    } catch (error) {
        proxy.$message.error('审核失败，请稍后重试');
        console.error('审核失败:', error);
    }
};

const rowStyle = () => {
    return {
        height: '60px',
    };
};

const getAuditStatusClass = (status) => {
    switch (status) {
        case 0:
            return 'pending-status';
        case 1:
            return 'approved-status';
        case 2:
            return 'rejected-status';
        default:
            return '';
    }
};

onMounted(() => {
    commissionAuditList();
});
</script>

<style scoped>
.custom-form .el-form-item {
    width: 360px;
}

.pending-status {
    color: #E6A23C;
}

.approved-status {
    color: #67C23A;
}

.rejected-status {
    color: #F56C6C;
}
</style>
